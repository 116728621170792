.home-container{
  background-color: black;
  height: 430vh;
  width: 100%;
  display: flex;
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
  object-fit: contain;

}

.home-img{
  height: 500px;
  width: 45%;
  background-image: url(/public/images/home2.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 30%;
  right: 50%;
  animation: fadeIn 5s;
}

.home-text{
  display: grid;
  place-items: center;
  position: absolute;
  top: 30%;
  left: 60%;
  animation: fadeIn 5s;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.home-text > h1{
  color: #fff;
  font-size: 80px;
}
.home-text > i{
  color: red;
  font-size: 30px;
}

.home-services{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 110%;
  right:0%;
  animation: fadeIn 5s;
}

.home-services > h1{
  color: red;
  
}

.home-services > p{
  color: #fff;
  font-size: 28px;
  position: relative;
  top: 100px;
}

.home-about-container{
  background-color: #222;
  display: flex;
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
  object-fit: contain;
  position: absolute;
  top: 180%;
  left: 10%;
  width: 80%;
  height: 40%
}

.home-about{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: absolute;
}

.home-about > h1{
  color: red;
  position: relative;
  top:30px;
}

.home-about > p{
  position: relative;
  top: 50px;
}

.hours-operation-container{
  height: 500px;
  width: 80%;
  background-image: url(/public/images/home.JPG);
  background-repeat: no-repeat;
  background-size: 50% 100%;
  background-color: #222;
  position: absolute;
  top:260%;
  left: 10%;
  
}

.hours-operation-container > h1{
  color: red;
  position: absolute;
  right:13%;
  top:20%;
}

.hours-operation-container > p{
  color: red;
  left: 25%;
  top:40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
 
}

.values-container{
  background-color: red;
  width: 100%;
  height:100vh;
  position: absolute;
  top: 360%;
}

.value-temp{
  background-color: white;
  height: 70%;
  width:40%;
  position: absolute;
  top:15%;
  left:6%
}

.core-img{
  height: 70%;
  width:40%;
  position: absolute;
  top:10%;
  left:10%;
  background-image: url(/public/images/corev.jpeg);
  background-size: 100% 100%;

}

.values > h1{
  color: white;
  position: absolute;
  left: 68%;
  top: 20%;

}

.cores{
  position: absolute;
  left:53%;
  top:10%;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 5%
 
}

@media screen and (max-width: 960px) {
 .home-container{
  width: 100%;
  height: 360vh
 }

 .home-img{
  height: 200px;
  top: 40%;
  left:15%;
  width: 70%
 }

 .home-text{
  top: 10%;
  left: 30%
 }

 .home-text > h1{
  font-size: 30px;
 }

 .home-text >i{
  font-size: 10px;
 }

 .home-services{
  top: 80%;
 }

 .home-about-container{
  height: 60%;
 }

 .hours-operation-container{
  background-size: 100% 100%;
  right:5%
  
 }

 .hours-operation-container > p{
  left:0%;
 }

 .values-container{
  height: 80vh;
 }
 
 .values > h1{
  top: 10px;
  left: 60%;
 }

 .cores > p{
  font-size: 15px;
 }
}