* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
  }
  
  .home
  {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
  
  .services {
   /** background-image: url('/images/img-2.jpg');**/
    background-color: #222;
    height: 200vh;
  }

  .about {
    background-color:  grey;
    height: 200vh;
  }

  .contact{
    background-color: #222;
    height: 200vh;
  }
  
