.about-container{
    background-color: #222;
    height: 200vh;
    width: 100%;
    display: flex;
}

.about-div{
    background-color: black;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    position: absolute;
    top: 15%;
    left:5%;
    height: 80%;
    width: 90%;
}

.about-div > h1{
    color: red;
    position: relative;
    top: 100px;
}

.about-div > p{
    color: white;
    position: relative;
    top: 150px;
    font-size: 30px;
}

.partner-div{
    background-color: black;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    position: absolute;
    top: 110%;
    left:5%;
    height: 80%;
    width: 90%;
    
}

.partner-div > h1{
    color: red;
    position: relative;
    top: 100px;
}

.partner-div > p{
    color: white;
    position: relative;
    top: 150px;
    font-size: 30px;
}

.kalmar{
    background-image: url(/public/images/kalmar.png);
    width: 22%;
    height: 20%;
    position: absolute;
    top: 50%;
    left: 20%;
    background-repeat: no-repeat;
    background-size: cover;
}

.heli{
    background-image:url(/public/images/heli.png) ;
    width: 22%;
    height: 20%;
    position: absolute;
    top: 50%;
    left: 60%;
    background-repeat: no-repeat;
    background-size: cover;
}

@media screen and (max-width: 960px) {
    .about-container{
        height: 160vh;
    }
    .about-div{
        height: 90%;
    }

    .about-div > p{
        font-size: 20px;
    }

    .partner-div{
        top: 110%;
    }

    .partner-div > p{
        font-size: 25px;
    }

    .kalmar{
        width: 53%;
        left: 25%;
    }

    .heli{
        width: 53%;
        top: 75%;
        left: 25%;
    }
}