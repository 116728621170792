.contact-container{
    background-color: #222;
    height: 200vh;
    width: 100%;
    display: flex;
}

.contact-div{
    background-color: black;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    position: absolute;
    top: 15%;
    left:5%;
    height: 50%;
    width: 45%;
}

.contact-div > h1{
    color: red;
    position: relative;
    top: 100px;
}

.contact-div > p{
    color: white;
    position: relative;
    top: 130px;
    font-size: 20px;
    padding: 1%;
}

.map-address > iframe{
    position: absolute;
    top: 15%;
    right: 5%;
}

.hours-div{
    background-color: red;
    height: 80vh;
    width: 100%;
    display: flex;
    position: absolute;
    top: 100%;
    display: grid;
    place-items: center;
}

.hours{
    background-color: black;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    position: absolute;
    height: 50%;
    width: 90%;
   
}

.hours > h1,p{
    color: white;
    position: relative;
    top: 50px;
    font-size: 20px;
    padding: 1%;
}

@media screen and (max-width: 960px) {
    .contact-container{
        height: 165vh;
    width: 100%
    }
.contact-div{
    top:60%;
    left:0%;
    width: 100%;
}

.hours-div{
    top:110%
}


}