.Services-container{
    background-color: #222;
    height: 200vh;
    width: 100%;
    display: flex; 
}

.rs-img{
    height: 30%;
    width: 20%;
    background-image: url(/public/images/repairs\ 2.JPG);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 30%;
    left: 10%;

}

.rs-div{
    background-color: black;
    height:20%;
    width: 20%;
    position: absolute;
    top: 60%;
    left: 10%;
    display: grid;
   place-items: center;
    
}

.rs-div > h1{
    color: red;
    position: absolute;
    top: 10px;
    font-size: 25px;
   
}

.rs-div > h4{
    color: white;
    position: absolute;
    top: 60px;
    font-size: 15px;
    
}

.ps-img{
    height: 30%;
    width: 20%;
    background-image: url(/public/images/parts.JPG);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 30%;
    left: 40%;
}

.ps-div{
    background-color: black;
    height:20%;
    width: 20%;
    position: absolute;
    top: 60%;
    left: 40%;
    display: grid;
   place-items: center;
}

.ps-div > h1{
    color: red;
    position: absolute;
    top: 10px;
    font-size: 25px;
    
    
}

.ps-div > h4{
    color: white;
    position: absolute;
    top: 60px;
    font-size: 15px;
   
}

.lt-img{
    height: 30%;
    width: 20%;
    background-image: url(/public/images/load.JPG);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 30%;
    left: 70%;
}

.lt-div{
    background-color: black;
    height:20%;
    width: 20%;
    position: absolute;
    top: 60%;
    left: 70%;
    display: grid;
   place-items: center;
}

.lt-div > h1{
    color: red;
    position: absolute;
    top: 10px;
    font-size: 25px;
   
    
}

.lt-div > h4{
    color: white;
    position: absolute;
    top: 60px;
    font-size: 15px;
    
}

.rt-img{
    height: 30%;
    width: 35%;
    background-image: url(/public/images/rental.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 90%;
    left: 10%;
}

.rt-div{
    background-color: black;
    height:20%;
    width: 35%;
    position: absolute;
    top: 120%;
    left: 10%;
    display: grid;
   place-items: center;
}

.rt-div > h1{
    color: red;
    position: absolute;
    top: 10px;
    font-size: 25px;
    
    
}

.rt-div > h4{
    color: white;
    position: absolute;
    top: 60px;
    font-size: 15px;
    
}

.nu-img{
    height: 30%;
    width: 35%;
    background-image: url(/public/images/sale.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 90%;
    left: 55%;
}

.nu-div{
    background-color: black;
    height:20%;
    width: 35%;
    position: absolute;
    top: 120%;
    left: 55%;
    display: grid;
   place-items: center;
}

.nu-div > h1{
    color: red;
    position: absolute;
    top: 10px;
    font-size: 25px;
    
    
}

.nu-div > h4{
    color: white;
    position: absolute;
    top: 60px;
    font-size: 15px;
    
}

@media screen and (max-width: 960px) {
    .Services-container{
     width: 100%;  
     height: 140vh; 
    }

    .rs-div{
        height:25%;
        top: 45%;
    }

    .rs-div > h4{
        top: 60%;
    }

    .ps-div{
        height:25%;
        top: 45%;
        
    }

    .ps-div > h4{
        top: 50%;
    }

    .lt-div{
        height:25%;
        top: 45%;
        
    }

    .lt-div > h4{
        top: 50%;
    }

    .nu-div > h4{
        top: 50%
    }

    .nu-img{
        background-size: 100% 50%;
    }

    .nu-div{
        top:105%
    }

    .rt-img{
        background-size: 100% 50%; 
    }

    .rt-div{
        top:105%
    }

    .lt-img{
        background-size: 100% 50%;
    }

    .ps-img{
        background-size: 100% 50%;
    }

    .rs-img{
        background-size: 100% 50%;
    }

}