.footer{
    background-color: black;
    display: grid;
    height: 20vh;
    place-items: center;
}

.footer > h4{

    color: white;
}



